/* Colors
matte black: #252525;
off white: #F8F7ED;
pink #D295BD;
 */


 /* /////Flip cards/ */

.flip-card {
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
}

.flip-title {
  font-size: 2rem;
  font-weight: bolder;
  text-transform: uppercase;
  color: #F8F7ED;
  border-bottom: 4px solid #D295BD;
}
.flip-description{
  font-size: 2rem;
  padding: 0px 25px;
  text-align: left;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #252525;
  color: #F8F7ED;
  transform: rotateY(180deg);
}

.preview-image{
  width: 400px;
  height: 400px;
}


/* ////////////Helpful css/// */

body {
  background-color: #252525;
  color: white;
  font-size: 1rem;
}

.hide{
  display: none;
}

body a{
  color: #F8F7ED;
}
.wrapper{
  padding: 0px 20px 0px 0px;
}


/* ///////ABOUT ABOVE// */
.above {
  /* height: 100vh; */
  font-size: .75rem;
}
.name{
  margin-top: 25px;
}
.sarah {
  font-size: 7rem;
  text-transform: uppercase;
  font-weight: bolder;
  color: #F8F7ED;
  margin: 0px 0px 10px -8px;
  line-height: 5.25rem;
  animation: slide-in ease 4s;
}
.rainbow-name {
  animation: rainbow ease 6s;
}
.pink {
  color: #D295BD;
}

.job-title {
  font-size: 1.5rem;
  font-weight: bolder;
  text-transform: uppercase;
  color: #F8F7ED;
  padding: 0px 0px 0px 10px;
}
.job-title:hover {

  color: #D295BD;
}
.about-p{
  padding: 0px 100px 0px 10px;
}
.soical-icons {
  padding: 0px 0px 0px 10px;
}

.soical-icons i{
  font-size: 2rem;
  padding: 25px 10px 5px 0px;
  color:#F8F7ED;
}
.soical-icons i:hover, .soical-icons i:active{
  color:#D295BD;
}

.phone-show {
  display: block;
  margin-top: 25px;
  margin-right: 10px;
  font-size: 1rem;
  font-weight: bold;
  animation: slide-in-short ease 1s;
}


.bar{
  margin: 0px 0px 50px 0px;
  width: 98%;
  height: 25px;
  background-color: #D295BD;
  /* animation: slide-in ease 1s; */
}

.white-bar {
  width: 93%;
  position: absolute;
  height: 25px;
  background-color: #F8F7ED;
  animation: slide-in ease 4s;
}


/* /////////// Navbar ///// */
.homepage-nav {
  background-color: #F8F7ED;
  text-align: center;
  /* padding: 10px; */
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
}

.homepage-nav a {
  color: #252525;
  /* padding: 0px 25px; */
  text-decoration: none;
}
.homepage-nav a:hover{
  color: #D295BD;
}

#easteregg {
  background-color: #D295BD;
}

/* ////////RESUME/ */

.resume-btn {
  padding: 3px 6px;
  border-radius: 3px;
  margin-top: auto;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  color:#252525;
  background-color: #F8F7ED;
}

.resume-btn:hover{
  background-color: #D295BD;
  text-decoration: none;
}

.resume-paper {
  animation: slide-down ease 2s;
  padding-top: 25px;

}
.resume-show a{
  text-decoration: none;
}
.resume-show {
  text-align: center;
}

.resume-show a{
  font-size: 1.5rem;
  padding: 5px;
}
.resume-show a:hover{
  color:#F8F7ED;
}
/* //////////////PORJECTS */

.project {
  margin-bottom: 100px;
}

.project-a{
  position: absolute;
  top: 0;
  width: 100%;
  /* margin: 0px 10px; */
  height: 200px;
}

.project-a:hover{
  opacity: .2;
}

.project-description-container{
  position: relative;
  text-align: center;
  /* height: 350px; */
  height: 50%;
  /* margin-bottom:75%; */
}
.project-description-p{
  font-size: .75rem;
}

.img-project {
  width: 100%;
  height: 100%;
}


.project-title{
  font-weight: bolder;
  font-size: 1rem;
  text-transform: uppercase;
  color:  #D295BD;
}

/* /////footer/// */

.footer{
  margin-top: 100px;
  width: 100%;
}

.footer-content{
  width: 100%;
  overflow: hidden;
  height: 50px;
  background-color: #F8F7ED;
}

.soical-icons-footer i{
  font-size: 2rem;
  padding: 0px 10px 5px 0px;
  color: #D295BD;
}
.soical-icons-footer i:hover, .soical-icons i:active{
  color:#252525;
}

.resume-footer-btn {
  padding: 3px 6px;
  border-radius: 3px;
  margin-top: auto;
  margin-bottom: 5px;
  font-weight: bold;
  color: #F8F7ED;
  background-color: #D295BD;
}

.resume-footer-btn:hover{
  background-color: #252525;
}

.phone-show-footer {
  margin-top: 25px;
  margin-right: 10px;
  font-size: 1.5rem;
  color: #D295BD;
  font-weight: bold;
  animation: slide-in-short ease 1s;
}

#projects {
  padding-left: 20px;
}

/* ////////// KEYFRAMES // */


@keyframes rainbow {
  0% {background-color: red }
  10% {background-color: orange }
  25% {background-color:  yellow}
  50% {background-color: green }
  75% {background-color: blue }
  100% {background-color: purple }
}

@keyframes slide-in {
  0% {transform: translateX(-1000px);
  opacity: 0;
  }
  100% {transform: translateX(0);
  opacity: 1;
  }
}
@keyframes slide-in-short {
  0% {transform: translateX(-10px);
  opacity: 0;
  }
  100% {transform: translateX(0);
  opacity: 1;
  }
}

@keyframes slide-down {
  0% {transform: translateY(-1000px);
  opacity: 0;
  }
  100% {transform: translateY(0);
  opacity: 1;
  }
}


/* /////// MEDIA QUERY/ */

@media (max-width: 500px) {
  .sarah{
    font-size: 4rem;
    line-height: 3rem;
  }
  .about-p{
    padding: 0px 100px 0px 0px;
  }
  .job-title {
    font-size: 1rem;
    padding: 0px 0px 0px 0px;
  }
  .soical-icons {
    padding: 0px 0px 0px 0px;
  }
  /* #projects {
    padding: 10px;
  } */

  .preview-image{
    width: 350px;
    height: 350px;
  }

  .flip-card {
    width: 350px;
    height: 350px;
  }

  .flip-title {
    font-size: 1.5rem;

  }
  .flip-description{
    font-size: 1.5rem;

  }


}
/* 
@media (min-width: 700px) {
  #projects {
    padding-left: 50px;
  }
}

@media (min-width: 900px) {
  #projects {
    padding-left: 60px;
  }
} */
/* 1150 */
/* .sarah {
  font-size: 6rem;
  margin: 0px 0px 10px -8px;
} */

/* 550 */
/* {
  font-size: 7rem;
  margin: 0px 0px 10px -8px;
} */
